import React from 'react';
import styled from '@emotion/styled';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';

const PostTags = styled.div`
    width: 100%;
    max-width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: 11rem;
    border-radius: 10px;
    position:relative;
    background: ${props => props.theme.colors.background.dark};
    transition: all .3s ease;
    box-shadow: 0 1px 1px 0 rgba(31, 35, 46, .15);
`;

const PostTagContent = styled.div`
    padding: 0;
    margin: 0;
    width: 100%;
    border-radius: 10px;
    color: ${props => props.theme.colors.primary.base};
`;

const PostTagTitleBlock = styled.div`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 1rem;
    background: ${props => props.theme.colors.background.light};
`;

const PostTagTitle = styled.h1`
    margin: 0 0 10px;
    font-size: 30px;
    font-weight: 400;
    color: ${props => props.theme.colors.primary.light};
`;

const TagsInfoContainer = styled.div`
  font-size: 12px;
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.link};
    &:hover {
      color: ${props => props.theme.colors.linkHover};
      text-decoration: underline;
    }
  }
`;

const PostTagBlockBottom = styled.div`
    padding: 1rem;
`;

const PostTagList = styled.ul`
    margin-top: 0;
    list-style-type: none;
    a {
        color: ${props => props.theme.colors.link};
        text-decoration: none;
        &:hover {
            color: ${props => props.theme.colors.linkHover};
            text-decoration: underline;
        }
    }
`;

const Suggestions = styled.div`
    box-shadow: ${props => props.theme.shadow.suggestion};
`;

const PostBlockSpacer = styled.div`
    background: ${props => props.theme.colors.background.medium};
    padding:0.5rem 0;
`;

const SuggestionBar = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  background: ${props => props.theme.colors.background.dark};
  border-top: 1px solid #777;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 0 2rem;
`;
const PostSuggestion = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  a {
    color: ${props => props.theme.colors.link};
    &:hover {
        color: ${props => props.theme.colors.linkHover};
    }
}
`;

const Tags = ({ data, pageContext }) => {
    const posts = data.allMarkdownRemark.edges;
    const { tag } = pageContext;

  return (
    <Layout title={`All posts tagged ${tag}`}>
        <PostTags>
            <PostTagContent>
                <PostTagTitleBlock>
                    <PostTagTitle>
                            {tag}
                    </PostTagTitle>
                    <TagsInfoContainer>
                    All posts tagged&nbsp;{tag}
                    </TagsInfoContainer>
                </PostTagTitleBlock>
                <PostTagBlockBottom>
                    <PostTagList>
                        {
                            posts.map(({ node }) => {
                                const { title, date } = node.frontmatter;
                                const { slug } = node.fields;

                                return(
                                    <li>
                                    <span key={slug}>
                                        <Link to={slug}>
                                            {title}
                                        </Link>
                                        <small><span> | {date}</span></small>
                                    </span>
                                    </li>
                                );
                            })
                        }
                    </PostTagList>
                </PostTagBlockBottom>
                <Suggestions>
                    <PostBlockSpacer />
                    <SuggestionBar>
                        <PostSuggestion>
                            ←&nbsp;
                            <Link to='/tags'>
                                All tags
                            </Link>
                        </PostSuggestion>
                    </SuggestionBar>
                </Suggestions>
            </PostTagContent>
        </PostTags>
    </Layout>
  )
}

export default Tags;

Tags.propTypes = {
    pageContext: PropTypes.shape({
      tag: PropTypes.string,
    }).isRequired,
    data: PropTypes.object.isRequired,
  };

  export const query = graphql`
      query PostsByTag($tag: String!) {
        allMarkdownRemark(
            filter: {frontmatter: {tags: {eq: $tag}}},
            sort: {fields: [frontmatter___date], order: DESC}
            ) {
            edges {
              node {
                fields {
                    slug
                }
                frontmatter {
                  date(formatString: "MMMM DD, YYYY")
                  title
                }
              }
            }
        }
      }
  `;